import React from "react"
import SedLogo from "../components/SedLogo"

export default function Home() {
  return <div style={{
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "95vh",
  }}>
    <SedLogo />
    <div style={{
      textAlign: "center",
      font: "normal normal bold 12px/30px Arial",
      color: "#FFFFFF",
    }}>contact : <a href="mailto:hello@shakingearth.digital" style={{color: "#FFFFFF"}}>hello@shakingearth.digital</a></div>
  </div>
}
